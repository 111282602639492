import MobileHeader from "./MobileHeader";

import logo from "./logo.svg";
import {Link} from "react-router-dom";
import React from "react";

import comedogenic from "./mb_comedogenic.png";
import about from "./mb_about.png";
import types from "./mb_types.png";
import GuideText from "./GuideText";

const mobileMenuItems = [
    {name: "Pretraga", image: comedogenic},
    {name: "Skener", image: about},
    {name: "Uputstvo", image: types},
];

const MbGuidelayout = () => (
    <div className="App" style={mbGuideStyles.fullWidth}>
        <div>
            <MobileHeader logo={logo} title="SKIIN"/>
            <hr style={mbGuideStyles.line}/>
            <nav style={mbGuideStyles.mobileMenuContainer}>
                {mobileMenuItems.map((item, index) => (
                    <Link key={index} to={`/${item.name.toLowerCase()}`} style={mbGuideStyles.mobileMenuItem}>
                        {/* Render the image */}
                        <img src={item.image} alt={item.name} style={mbGuideStyles.mobileMenuImage}/>
                        {/* Render the name of the menu item */}
                        {item.name}
                    </Link>
                ))}
            </nav>

            <div>
                <hr style={mbGuideStyles.line}/>
                <GuideText></GuideText>
            </div>
        </div>
    </div>
);

export default MbGuidelayout;

const mbGuideStyles = {
    textClean: {
        fontSize: '1.5rem',
        color: '#5ac8de',
    },
    image: {
        width: '70px',
        height: '70px'
    },
    containerClean: {
        width: '100%',
        marginTop: '100px',
        alignItems: 'center',
    },
    line: {
        width: '100%',
        alignSelf: 'stretch',
        border: 'none',
        borderTop: '1px solid #8adced',
        margin: '0',
        padding: '0',
        background: '#C9F1F8',
    },
    mobileMenuContainer: {
        width: '100vw',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '25px',
        marginBottom: '25px',
    },
    fullWidth: {
        height: '100%',
        width: '100%',
        display: 'flex',
        position: 'absolute',
    },
    mobileMenuItem: {
        textDecoration: 'none',
        color: '#5AC8DE',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    mobileMenuImage: {
        width: '80px',
        height: '80px',
        marginBottom: '5px',
        boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '50%',
        objectFit: 'cover',
    },
    mobileInputContainer: {
        marginTop: '25px',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '25px',
        marginRight: '60px',
        background: '#C9F1F8',
    },
    mobileInput: {
        width: '95%',
        padding: '25px',
        fontSize: '1rem',
        border: '1px solid #8adced',
        borderRadius: '15px',
        outline: 'none',
        color: '#5ac8de',
    },
    mobileRunButton: {
        width: '50px',
        height: '50px',
        cursor: 'pointer',
        marginTop: '40px',
        position: 'absolute',
        right: '40px',
    },
}