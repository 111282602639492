
const DescriptionText = () => {
    return (
        <div style={styles.div}>
            <div style={styles.text}>
                <p style={styles.greenText}>
                    Zdravo i dobrodošli!
                    <br/><br/>
                    Ova web stranica rezultat je lične borbe koju sam vodila protiv akni više od decenije.
                    Bez obzira na to šta sam pokušala ništa nije pomagalo.
                    <br/><br/>
                    Tek kada sam otkrila online alate koji razvrstavaju kozmetičke sastojke na komedogene
                    i nekomedogene, konačno sam počela da primećujem napredak.
                    <br/><br/>
                    Danas sa ponosom predstavljam alat koji sam sama napravila pre svega za svoje potrebe,
                    zasnovan na istraživanjima medicinskih stručnjaka u ovoj oblasti. Moja najveća želja
                    je da ovo znanje učinim dostupnim svima koji se bore sa aknama.
                    <br/><br/>
                    Nadam se da će vam ova stranica pomoći, baš kao što je pomogla meni.
                    <br/><br/>
                    Želim vam čistu kožu i samopouzdanje,
                    Anika
                </p>
            </div>
        </div>
    );
};

const styles = {
    div: {
        paddingLeft: '25px',
        display: 'grid',
        gridAutoFlow: 'column',
    },
    text: {
        paddingLeft: '25px',
        paddingRight: '25px',
        textAlign: 'start',
        fontSize: '1rem',
    },
    greenText: {
        fontSize: '1.2rem',
        color: '#5ac8de',
        fontFamily: "Caveat",
    },
};

export default DescriptionText;