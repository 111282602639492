// Header.js
import React from 'react';

const Header = ({logo, title}) => {

    return (
        <header style={styles.header}>
            <div style={styles.headerContainer}>
                <img src={logo} style={styles.logo}/>
                <h1>{title}</h1>
            </div>
        </header>
    );
};

const styles = {
    header: {
        backgroundColor: '#DDFBFF',
        color: '#5ac8de',
        paddingLeft: '55px',
        textAlign: 'start',
        minHeight: '70px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    logo: {
        display: 'flex',
        width: '50px',
        height: '50px',
        objectFit: 'contain',
    }
};

export default Header;
