const GuideText = () => {
    return (
        <div style={styles.div}>
            <div style={styles.text}>
                <p style={styles.greenText}><br/>
                    Ova web stranica služi kao vaš lični, džepni kalkulator komedogenosti. Jednostavno unesite
                    sastojke proizvoda koji razmatrate i oceniće da li on ima potencijal da zapuši pore.
                    <br/><br/>
                    Iako svaka koža drugačije reaguje na datu kombinaciju sastojaka, istraživanja su identifikovala
                    određene supstance kao problematične, posebno za osobe sa masnom kožom.
                    <br/><br/>
                    Komedogenost se obično meri skalom od 0–3 ili 0–5, pri čemu se većina studija oslanja na
                    Rabbit Ear Assay (REA). Pošto je koža kunića osetljivija od ljudske, sastojci koji na ovoj
                    skali dobiju ocenu 1 ne utiču na ljudsku kožu. Zato su takvi sastojci isključeni iz naše
                    baze podataka, kako bismo vam pružili preciznije informacije.
                    <br/><br/>
                    U budućnosti, ova web stranica će korisnicima ponuditi i detaljnije informacije o sastojcima
                    kojima raspolaže, izvore informacija, ali i svoju miksturu preko online prodavnice.
                    <br/><br/>
                    Do tada, ostanite informisani i srećno pretraživanje!
                </p>
            </div>
        </div>
    );
};

const styles = {
    div: {
        paddingLeft: '25px',
        display: 'grid',
        gridAutoFlow: 'column',
    },
    text: {
        paddingLeft: '25px',
        paddingRight: '45px',
        textAlign: 'start',
        fontSize: '1rem',
    },
    greenText: {
        fontSize: '1rem',
        color: '#5ac8de',
    },
};

export default GuideText;