import MbAboutLayout from "./MbAboutLayout";
import AboutLayout from "./AboutLayout";

import React, {useEffect, useState} from "react";
import aboutStyles from "./DesktopLayout";
import mbAboutStyles from "./MbAboutLayout";
import axios from "axios";
import comedogenic from "./comedogenic.png";

function useIsMobile() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

    useEffect(() => {
        const handleResize = () => {
            const newIsMobile = window.innerWidth < 480;
            setIsMobile(newIsMobile);  // Update the isMobile state based on window width
        };

        window.addEventListener("resize", handleResize);
        handleResize();  // Check once on mount to set initial state

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);  // Empty dependency array to ensure the effect runs only once

    return isMobile;
}

const About = () => {

    const isMobile = useIsMobile();
    const [isComedogenic, setIsComedogenic] = useState('');
    const [items, setItems] = useState([]);
    const [query, setQuery] = useState('');
    const formattedIngredients = formatIngredients(query);

    let ingredients = []

    const handleChange = (event) => {
        setQuery(event.target.value);
    };
    const handleButtonClick = () => {
        setItems([]);
        setIsComedogenic("loading");

        ingredients = formattedIngredients
        searchField();
    };
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleButtonClick();
        }
    };
    const searchField = async () => {
        try {
            const response = await axios.post('https://skiin.site/api/data', ingredients, {
                headers: {'Content-Type': 'application/json'}
            });
            if (Array.isArray(response.data)) {
                setItems([]);
                setIsComedogenic(ingredients.length > 0 ? "yes" : "no");
                response.data.forEach((item, index) => {
                    addIngredient(item)
                });
            } else {
                console.error('The response is not an array:', response);
            }
        } catch (error) {
            if (error.response) {
                console.error('Server responded with:', error.response.status, error.response.data);
            }
        }
    };
    const addIngredient = (ingredient) => {
        if (!ingredient) return; // Guard clause for empty text
        setItems((prevItems) => [...prevItems,
            {
                id: prevItems.length + 1,
                name: ingredient.name,
                category: ingredient.category,
                comrating: ingredient.comrating,
                cas: ingredient.cas,
                ec: ingredient.ec,
                source: ingredient.source,
                image: comedogenic
            }]);
        setQuery("");
    };

    function formatIngredients(ingredientsString) {
        const cleanedString = ingredientsString.replace(/\([^)]*\)/g, "");
        const ingredientsArray = cleanedString.split(/[,/|.:;*·]+/).filter(item => item.trim() !== "");
        return ingredientsArray.map(ingredient => ingredient.trim());
    }

    return (
        <div className="App" style={isMobile ? mbAboutStyles.fullWidth : aboutStyles.page}>
            {isMobile
                ?
                <MbAboutLayout
                    query={query}
                    handleChange={handleChange}
                    handleKeyDown={handleKeyDown}
                    handleButtonClick={handleButtonClick}
                    items={items}
                    isComedogenic={isComedogenic}/>
                :
                <AboutLayout
                    query={query}
                    handleChange={handleChange}
                    handleKeyDown={handleKeyDown}
                    handleButtonClick={handleButtonClick}
                    items={items}
                    isComedogenic={isComedogenic}
                />
            })
        </div>
    );
}

export default About;
