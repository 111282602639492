import React from 'react';
import Tesseract from 'tesseract.js';
import Webcam from 'react-webcam';
import axios from "axios";
import ScannedIngredientList from "./ScannedIngredientList";
import MobileHeader from "./MobileHeader";
import logo from "./logo.svg";
import { Link } from "react-router-dom";
import comedogenic from "./mb_comedogenic.png";
import about from "./mb_about.png";
import types from "./mb_types.png";

const mobileMenuItems = [
    { name: "Pretraga", image: comedogenic },
    { name: "Skener", image: about },
    { name: "Uputstvo", image: types },
];

class Scanner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ingredientsList: [],
            isLoading: false,
            lastScannedText: "", // Store last scanned text
            apiCallInProgress: false, // Flag to avoid repeated API calls
        };
        this.webcamRef = React.createRef();
        this.scanIntervalRef = null;
    }

    componentDidMount() {
        this.scanIntervalRef = setInterval(() => {
            this.handleCapture();
        }, 2000); // Scan every 5 seconds
    }

    componentWillUnmount() {
        clearInterval(this.scanIntervalRef);
    }

    async handleCapture() {
        const imageSrc = this.webcamRef.current.getScreenshot();
        if (!imageSrc) return;

        try {
            this.setState({ isLoading: true });

            // Preprocess the image
            const enhancedImage = await this.preprocessImage(imageSrc);

            // Resize image if necessary
            const resizedImage = await this.resizeImage(enhancedImage);

            // Run OCR with enhanced image
            const { data: { text } } = await Tesseract.recognize(resizedImage, 'eng', {
                logger: (m) => console.log(m),
                psm: 6  // Adjust for block of text
            });

            const cleanedText = text.trim();

            // Only send API request if text is different from last scan
            if (cleanedText && cleanedText !== this.state.lastScannedText) {
                this.setState({ lastScannedText: cleanedText });
                const ingredients = this.formatIngredients(cleanedText);

                // Check if API call is already in progress
                if (this.state.apiCallInProgress) {
                    console.log("API call already in progress, skipping this request.");
                    this.setState({ isLoading: false });
                    return;
                }

                // Start API call and mark as in progress
                this.setState({ apiCallInProgress: true });

                await this.handleScannedIngredients(ingredients);

                // Mark API call as completed
                this.setState({ apiCallInProgress: false });
            }

            this.setState({ isLoading: false });
        } catch (err) {
            console.error('OCR error:', err);
            this.setState({ isLoading: false });
        }
    }

    preprocessImage(imageSrc) {
        const img = new Image();
        img.src = imageSrc;

        return new Promise((resolve, reject) => {
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0, img.width, img.height);

                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const data = imageData.data;

                // Convert image to grayscale
                for (let i = 0; i < data.length; i += 4) {
                    const r = data[i];
                    const g = data[i + 1];
                    const b = data[i + 2];
                    const avg = (r + g + b) / 3;
                    data[i] = data[i + 1] = data[i + 2] = avg; // Apply grayscale
                }

                ctx.putImageData(imageData, 0, 0);
                resolve(canvas.toDataURL('image/jpeg'));
            };

            img.onerror = (err) => reject(err);
        });
    }

    resizeImage(imageSrc, maxWidth = 1024, maxHeight = 1024) {
        const img = new Image();
        img.src = imageSrc;

        return new Promise((resolve, reject) => {
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                let width = img.width;
                let height = img.height;

                if (width > maxWidth || height > maxHeight) {
                    const ratio = Math.min(maxWidth / width, maxHeight / height);
                    width = width * ratio;
                    height = height * ratio;
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);
                resolve(canvas.toDataURL('image/jpeg'));
            };

            img.onerror = (err) => reject(err);
        });
    }

    formatIngredients(ingredientsString) {
        const cleanedString = ingredientsString.replace(/\([^)]*\)/g, ""); // Remove text inside brackets
        const ingredientsArray = cleanedString.split(/[,/|.:;*·\n-]+/).filter(item => item.trim() !== "");
        return ingredientsArray.map(ingredient => ingredient.trim());
    }

    async handleScannedIngredients(ingredients) {
        try {
            const response = await axios.post('https://skiin.site/api/data', ingredients, {
                headers: { 'Content-Type': 'application/json' }
            });

            if (Array.isArray(response.data)) {
                this.setState((prevState) => {
                    const uniqueIngredients = new Map(prevState.ingredientsList.map(item => [item.name, item]));
                    response.data.forEach(item => uniqueIngredients.set(item.name, item));

                    return { ingredientsList: Array.from(uniqueIngredients.values()) };
                });
            } else {
                console.error('The response is not an array:', response);
            }
        } catch (error) {
            console.error('Error in API call:', error);
        }
    }

    render() {
        return (
            <div className="App" style={mobileStyles.fullWidth}>
                <div>
                    <MobileHeader logo={logo} title="SKIIN"/>
                    <hr style={mobileStyles.line}/>
                    <nav style={mobileStyles.mobileMenuContainer}>
                        {mobileMenuItems.map((item, index) => (
                            <Link key={index} to={`/${item.name.toLowerCase()}`} style={mobileStyles.mobileMenuItem}>
                                <img src={item.image} alt={item.name} style={mobileStyles.mobileMenuImage}/>
                                {item.name}
                            </Link>
                        ))}
                    </nav>
                    <div>
                        <hr style={mobileStyles.line}/>
                        <Webcam
                            audio={false}
                            ref={this.webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={{
                                width: 1920,
                                height: 1080,
                                facingMode: "environment",
                                focusMode: "continuous",
                                zoom: 2.0,
                            }}
                            style={{
                                borderRadius: "10px",
                                width: "80vw",
                                height: "auto",
                                maxHeight: "30vh",
                                objectFit: "cover",
                                marginBottom: "20px",
                                marginTop: "24px"
                            }}
                        />
                        <hr style={mobileStyles.line}/>
                        <div style={mobileStyles.div}>
                            <img
                                src="https://i.gifer.com/ZZ5H.gif"
                                alt="Scanning..."
                                style={{ width: "20px", height: "20px" }}
                            />
                            <p style={mobileStyles.text}>Skeniram sastojke</p>
                        </div>
                        {/* Scrollable Ingredient List */}
                        <div style={mobileStyles.scrollContainer}>
                            <ScannedIngredientList items={this.state.ingredientsList}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mobileStyles = {
    text: { fontSize: '1rem', color: '#5ac8de', marginLeft: "4px", padding: 0 },
    div: { display: "flex", alignItems: "center", marginLeft: "44px" },
    textClean: { fontSize: '1.5rem', color: '#5ac8de', },
    image: { width: '70px', height: '70px' },
    containerClean: { width: '100%', marginTop: '100px', alignItems: 'center', },
    line: {
        width: '100%',
        alignSelf: 'stretch',
        border: 'none',
        borderTop: '1px solid #8adced',
        margin: '0',
        padding: '0',
        background: '#C9F1F8',
    },
    mobileMenuContainer: {
        width: '100vw',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '25px',
        marginBottom: '25px',
    },
    fullWidth: { height: '100%', width: '100%', display: 'flex', position: 'absolute', },
    mobileMenuItem: {
        textDecoration: 'none',
        color: '#5AC8DE',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    mobileMenuImage: {
        width: '80px',
        height: '80px',
        marginBottom: '5px',
        boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '50%',
        objectFit: 'cover',
    },
    mobileInputContainer: {
        marginTop: '25px',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '25px',
        marginRight: '60px',
        background: '#C9F1F8',
    },
    mobileInput: {
        width: '95%',
        padding: '25px',
        fontSize: '1rem',
        border: '1px solid #8adced',
        borderRadius: '15px',
        outline: 'none',
        color: '#5ac8de',
    },
    mobileRunButton: {
        width: '50px',
        height: '50px',
        cursor: 'pointer',
        marginTop: '40px',
        position: 'absolute',
        right: '40px',
    },
    scrollContainer: { flex: 1, overflowY: 'auto', padding: '0px' },
};

export default Scanner;
