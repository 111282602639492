import React from 'react';
import one from './one.png';
import two from './two.png';
import three from './three.png';
import four from './four.png';
import five from './five.png';

const ScannedIngredientList = ({ items }) => {

    const comratingToImage = {
        "1": one,
        "2": two,
        "3": three,
        "4": four,
        "5": five,
        "1-2": one,
        "2-3": two,
        "3-4": three,
        "4-5": four,
        "1-3": two,
        "0-2": two,
        "0-3": two,
    };

    return (
        <div>
            {items.length > 0 ? (
                items.map((item) => (
                    <div style={styles.container} key={item.name}>
                        <p style={styles.text}>{item.name}</p>
                        {comratingToImage[item.comrating] ? (
                            <img src={comratingToImage[item.comrating]} style={styles.comrating} alt={item.comrating} />
                        ) : (
                            <p></p>
                        )}
                    </div>
                ))
            ) : (
                ""
            )}
        </div>
    );
};

const styles = {
    container: {
        marginLeft: '44px',
        width: '80%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    text: {
        textAlign: 'start',
        fontSize: '0.8rem',
        color: '#5ac8de',
    },
    comrating: {
        height: '12px',
    },
};

export default ScannedIngredientList;
