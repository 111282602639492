import Header from "./Header";
import PinkTextHeadline from "./PinkTextHeadline";
import IngredientList from "./IngredientList";

import {Link} from "react-router-dom";
import search from "./search.svg";
import React from "react";

import logo from "./logo.svg";
import comedogenic from "./mb_comedogenic.png";
import about from "./mb_about.png";
import types from "./mb_types.png";
import DescriptionText from "./DescriptionText";

const menuItems = [
    {name: "Pretraga", image: comedogenic},
    {name: "Šta je SKIIN?", image: about},
    {name: "Uputstvo", image: types},
];

const AboutLayout = ({query, handleChange, handleKeyDown, handleButtonClick, items, isComedogenic}) => (
    <div className="App">
        <div>
            <Header logo={logo} title="SKIIN"/>
            <div style={aboutStyles.page}>
                <hr style={aboutStyles.line}/>
                <div>
                    <nav style={aboutStyles.menuContainer}>
                        {menuItems.map((item, index) => (
                            <Link key={index} to={`/${item.name.toLowerCase()}`} style={aboutStyles.menuItem}>
                                {/* Render the image */}
                                <img src={item.image} alt={item.name} style={aboutStyles.menuImage}/>
                                {/* Render the name of the menu item */}
                                {item.name}
                            </Link>
                        ))}
                    </nav>
                </div>

                <div style={aboutStyles.searchSide}>
                    <PinkTextHeadline text="Ovde unesi sastojke"></PinkTextHeadline>
                    <div>
                        <input className="centered"
                               type="text"
                               value={query}
                               onChange={handleChange}
                               onKeyDown={handleKeyDown}
                               placeholder="Sastojci..."
                               style={aboutStyles.input}/>
                        <img src={search} alt="Search" onClick={handleButtonClick} style={aboutStyles.runButton}/>
                    </div>
                    <IngredientList items={items} isComedogenic={isComedogenic}/>
                    {isComedogenic === "loading" && <div style={aboutStyles.containerClean}><p style={aboutStyles.textClean}>Loading</p></div>} {/* Add this */}
                    {isComedogenic === "" && <div style={aboutStyles.containerClean}></div>}
                </div>
                <div style={aboutStyles.aboutSide}>
                    <DescriptionText></DescriptionText>
                </div>
            </div>
        </div>
    </div>
);

export default AboutLayout;

const aboutStyles = {
    textClean: {
        fontSize: '1.5rem',
        color: '#5ac8de',
    },
    image: {
        width: '70px',
        height: '70px'
    },
    containerClean: {
        width: '100%',
        marginTop: '100px',
        alignItems: 'center',
    },
    page: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        display: 'flex',
    },
    searchSide: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: '#C9F1F8',
    },
    line: {
        width: '100%',
        position: 'absolute',
        display: 'flex',
        border: 'none',
        borderTop: '1px solid #8adced',
        margin: '0 0px',
    },
    input: {
        width: '80%',
        padding: '25px',
        fontSize: '1rem',
        border: '1px solid #8adced',
        borderRadius: '15px',
        marginBottom: '25px',
        outline: 'none',
        color: '#5ac8de'
    },
    runButton: {
        width: '50px',
        height: '50px',
        background: '#fff',
        cursor: 'pointer',
        marginBottom: '-20px',
        marginLeft: '-62px'
    },
    aboutSide: {
        paddingRight: '15px',
        paddingTop: '50px',
        height: '100%',
        width: '100%',
        background: '#DDFBFF',
        display: 'flex',
        flexDirection: 'column',
    },
    menuContainer: {
        height: '100%',
        background: '#DDFBFF',
        paddingTop: '50px',
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        marginLeft: 'auto',
    },
    menuItem: {
        textDecoration: 'none',
        width: '200px',
        height: '95px',
        color: '#5ac8de',
        display: 'flex',
        alignItems: 'center',
        background: '#C9F1F8',
        marginLeft: '50px',
        borderBottomLeftRadius: '100px',
        borderTopLeftRadius: '100px',
    },
    menuImage: {
        boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '50%',
        objectFit: 'cover',
        width: '100px',
        height: '100px',
        marginRight: '25px',
        flexDirection: 'column',
        alignItems: 'center',
        display: 'flex',
    },
}