const MobilePinkTextHeadline = ({ text }) => {
    return (
        <text style={styles.text}>{text}</text>
    );
};

const styles = {
    text: {
        fontStyle: 'italic',
        fontSize: '1rem',
        color: '#FF007A',
        textAlign: 'left',
        marginBottom: '10px',
        marginLeft: '25px'
    },
};

export default MobilePinkTextHeadline;