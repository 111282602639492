import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import About from "./About";
import Home from "./Home";
import Guide from "./Guide";
import React from "react";
import Scanner from "./Scanner";

const App = () => (
    <Router>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/Pretraga" element={<Home/>}/>
            <Route path="/Šta je SKIIN?" element={<About/>}/>
            <Route path="/Uputstvo" element={<Guide/>}/>
            <Route path="/Skener" element={<Scanner/>}/>
        </Routes>
    </Router>
);

export default App;